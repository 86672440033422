import { template as template_a66ac1b63b564069b2b7d4b5015efcbc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_a66ac1b63b564069b2b7d4b5015efcbc(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
