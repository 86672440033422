import { template as template_aa2a88af1959403ebdc78e632efcbf18 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aa2a88af1959403ebdc78e632efcbf18(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
