import { template as template_e29ba6ef599045a4a45c231bb97a4f09 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_e29ba6ef599045a4a45c231bb97a4f09(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
