import { template as template_fca57e50caaa40b2b7b7dcb8b20b34be } from "@ember/template-compiler";
const WelcomeHeader = template_fca57e50caaa40b2b7b7dcb8b20b34be(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
