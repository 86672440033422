import { template as template_e4cd6e7c3dcb4d569c9fbd3e8adb733c } from "@ember/template-compiler";
const EmptyState = template_e4cd6e7c3dcb4d569c9fbd3e8adb733c(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
